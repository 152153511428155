import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TableSchemaService from 'services/TableSchemaService';

export const initialState = {
	objectListData: {
		objects: [],
		isLoading: false,
		isLoaded: false,
	},
	selectedObjectData: {
		object: null,
		isLoading: false,
		isLoaded: false,
	}
}


export const createObject = createAsyncThunk('tableSchema/createObject', async (data, { rejectWithValue }) => {
	try {
		const response = await TableSchemaService.createObject(data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const getObjects = createAsyncThunk('tableSchema/getObjects', async (params, { rejectWithValue }) => {
	try {
		const response = await TableSchemaService.getObjects(params);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const getObject = createAsyncThunk('tableSchema/getObject', async ({id, params}, { rejectWithValue }) => {
	try {
		const response = await TableSchemaService.getObject(id, params);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const updateObject = createAsyncThunk('tableSchema/updateObject', async ({id, data}, { rejectWithValue }) => {
	try {
		const response = await TableSchemaService.updateObject(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const deleteObject = createAsyncThunk('tableSchema/deleteObject', async (id, { rejectWithValue }) => {
	try {
		const response = await TableSchemaService.deleteObject(id);
		return id
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const uploadXLSXFile = createAsyncThunk('tableSchema/uploadXLSXFile', async ({id, data}, { rejectWithValue }) => {
	try {
		const response = await TableSchemaService.uploadXLSXFile(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const addFieldInStructure = createAsyncThunk('tableSchema/addFieldInStructure', async ({id, data}, { rejectWithValue }) => {
	try {
		const response = await TableSchemaService.addFieldInStructure(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const deleteStructure = createAsyncThunk('tableSchema/deleteStructure', async ({id, structureId}, { rejectWithValue }) => {
	try {
		const response = await TableSchemaService.deleteStructure(id, structureId);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const createStructure = createAsyncThunk('tableSchema/createStructure', async ({id, data}, { rejectWithValue }) => {
	try {
		const response = await TableSchemaService.createStructure(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const updateStructure = createAsyncThunk('tableSchema/updateStructure', async ({id, structureId, data}, { rejectWithValue }) => {
	try {
		const response = await TableSchemaService.updateStructure(id, structureId, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const sortStructure = createAsyncThunk('tableSchema/sortStructure', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await TableSchemaService.sortStructure(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const updateAutoMatchFields = createAsyncThunk('tableSchema/updateAutoMatchFields', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await TableSchemaService.updateAutoMatchFields(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const assignUsers = createAsyncThunk('tableSchema/assignUsers', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await TableSchemaService.assignUsers(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const objectSlice = createSlice({
	name: 'tableSchema',
	initialState,
	reducers: {
		setSelectedObjectData: (state, action) => {
			const { object } = action?.payload;
			state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getObjects.fulfilled, (state, action) => {
				const { objects, page, totalPages, totalRecords, limit } = action?.payload?.result
				state.objectListData = { ...state.objectListData, objects, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
			})
			.addCase(getObjects.pending, (state) => {
				state.objectListData = { ...state.objectListData, isLoaded: false, isLoading: true }
			})
			.addCase(getObjects.rejected, (state) => {
				state.objectListData = { ...state.objectListData, isLoaded: true, isLoading: false }
			})
			.addCase(getObject.fulfilled, (state, action) => {
				const { object } = action?.payload;
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
			})
			.addCase(getObject.pending, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isLoaded: false, isLoading: true }
			})
			.addCase(getObject.rejected, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isLoaded: true, isLoading: false }
			})
			.addCase(deleteObject.fulfilled, (state, action) => {
				const objectId = action?.payload;
				const objects = state.objectListData.objects.filter(object => object?._id !== objectId);
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(uploadXLSXFile.fulfilled, (state, action) => {
				const object = action?.payload;
				const objects = [ ...state.objectListData.objects.filter(item => item?._id !== object?._id), object];
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(addFieldInStructure.fulfilled, (state, action) => {
				const object = action?.payload;
				const objects = [ ...state.objectListData.objects.filter(item => item?._id !== object?._id), object];
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(updateObject.fulfilled, (state, action) => {
				const object = action?.payload;
				const index = state.objectListData.objects.findIndex(item => item?._id == object?._id)
				const objects = [ ...state.objectListData.objects ];
				objects.splice(index, 1, object)
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(deleteStructure.fulfilled, (state, action) => {
				const object = action?.payload;
				const objects = [ ...state.objectListData.objects.filter(item => item?._id !== object?._id), object];
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(createStructure.fulfilled, (state, action) => {
				const object = action?.payload;
				const objects = [ ...state.objectListData.objects.filter(item => item?._id !== object?._id), object];
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(updateStructure.fulfilled, (state, action) => {
				const object  = action?.payload;
				state.selectedObjectData = { ...state.selectedObjectData, object, isEditLoading: false }
			})
			.addCase(updateStructure.pending, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isEditLoading: true }
			})
			.addCase(updateStructure.rejected, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isEditLoading: false }
			})
			.addCase(sortStructure.fulfilled, (state, action) => {
				const object  = action?.payload;
				state.selectedObjectData = { ...state.selectedObjectData, object, isEditLoading: false }
			})
			.addCase(sortStructure.pending, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isEditLoading: true }
			})
			.addCase(sortStructure.rejected, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isEditLoading: false }
			})
			.addCase(updateAutoMatchFields.fulfilled, (state, action) => {
				const object  = action?.payload;
				state.selectedObjectData = { ...state.selectedObjectData, object, isEditLoading: false }
			})
			.addCase(updateAutoMatchFields.pending, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isEditLoading: true }
			})
			.addCase(updateAutoMatchFields.rejected, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isEditLoading: false }
			})
			.addCase(assignUsers.fulfilled, (state, action) => {
				const object = action?.payload;
				const index = state.objectListData.objects.findIndex(item => item?._id == object?._id)
				const objects = [ ...state.objectListData.objects ];
				objects.splice(index, 1, object)
				state.objectListData = { ...state.objectListData, objects }
			})
			
	},
})

export const {
	setSelectedObjectData
} = objectSlice.actions

export default objectSlice.reducer