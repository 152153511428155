import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import MetabaseService from 'services/MetabaseService';

export const initialState = {
	metabaseData: {
		url: [],
		isLoading: false,
		isLoaded: false,
	},
	dashboard: {
		objects: [],
		isLoading: false,
		isLoaded: false,
	},
	
}



export const getMetabaseData = createAsyncThunk('metabase/getMetabaseData', async (id, { rejectWithValue }) => {
	try {
		const response = await MetabaseService.getData(id);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const getDashboards = createAsyncThunk('metabase/getDashboards', async (params, { rejectWithValue }) => {
	try {
		const response = await MetabaseService.getDashboards(params);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})




export const metabaseSlice = createSlice({
	name: 'metabase',
	initialState,
	reducers: {
		
	},
	extraReducers: (builder) => {
		builder
			.addCase(getMetabaseData.fulfilled, (state, action) => {
				const { url } = action?.payload?.result
				state.metabaseData = { ...state.metabaseData, url, isLoaded: true, isLoading: false }
			})
			.addCase(getMetabaseData.pending, (state) => {
				state.metabaseData = { ...state.metabaseData, isLoaded: false, isLoading: true }
			})
			.addCase(getMetabaseData.rejected, (state) => {
				state.metabaseData = { ...state.metabaseData, isLoaded: true, isLoading: false }
			})
			.addCase(getDashboards.fulfilled, (state, action) => {
				const { objects } = action?.payload?.result
				state.dashboard = { ...state.dashboard, objects, isLoaded: true, isLoading: false }
			})
			.addCase(getDashboards.pending, (state) => {
				state.dashboard = { ...state.dashboard, isLoaded: false, isLoading: true }
			})
			.addCase(getDashboards.rejected, (state) => {
				state.dashboard = { ...state.dashboard, isLoaded: true, isLoading: false }
			})
			
			
	},
})



export default metabaseSlice.reducer