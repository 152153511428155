import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ProjectService from 'services/ProjectService';
import WorkspaceService from 'services/WorkspaceService';

export const initialState = {
	objectListData: {
		objects: [],
		isLoading: false,
		isLoaded: false,
	},
	selectedObjectData: {
		object: null,
		isLoading: false,
		isLoaded: false,
	}
}


export const createObject = createAsyncThunk('workspace/createObject', async (data, { rejectWithValue }) => {
	try {
		const response = await WorkspaceService.createObject(data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const getObjects = createAsyncThunk('workspace/getObjects', async (params, { rejectWithValue }) => {
	try {
		const response = await WorkspaceService.getObjects(params);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const getObject = createAsyncThunk('workspace/getObject', async (id, { rejectWithValue }) => {
	try {
		const response = await WorkspaceService.getObject(id);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const updateObject = createAsyncThunk('workspace/updateObject', async ({id, data}, { rejectWithValue }) => {
	try {
		const response = await WorkspaceService.updateObject(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const deleteObject = createAsyncThunk('workspace/deleteObject', async (id, { rejectWithValue }) => {
	try {
		const response = await WorkspaceService.deleteObject(id);
		return id
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const assignUsers = createAsyncThunk('workspace/assignUsers', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await WorkspaceService.assignUsers(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const assignProjects = createAsyncThunk('workspace/assignProjects', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await WorkspaceService.assignProjects(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const workspaceSlice = createSlice({
	name: 'workspace',
	initialState,
	reducers: {
		setSelectedObjectData: (state, action) => {
			const { object } = action?.payload;
			state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getObjects.fulfilled, (state, action) => {
				const { objects, page, totalPages, totalRecords, limit } = action?.payload?.result
				state.objectListData = { ...state.objectListData, objects, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
			})
			.addCase(getObjects.pending, (state) => {
				state.objectListData = { ...state.objectListData, isLoaded: false, isLoading: true }
			})
			.addCase(getObjects.rejected, (state) => {
				state.objectListData = { ...state.objectListData, isLoaded: true, isLoading: false }
			})
			.addCase(getObject.fulfilled, (state, action) => {
				const { object } = action?.payload;
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
			})
			.addCase(getObject.pending, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isLoaded: false, isLoading: true }
			})
			.addCase(getObject.rejected, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isLoaded: true, isLoading: false }
			})
			.addCase(deleteObject.fulfilled, (state, action) => {
				const id = action?.payload;
				const objects = state.objectListData.objects.filter(object => object?._id !== id);
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(assignUsers.fulfilled, (state, action) => {
				const object = action?.payload;
				const index = state.objectListData.objects.findIndex(item => item?._id == object?._id)
				const objects = [ ...state.objectListData.objects ];
				objects.splice(index, 1, object)
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(assignProjects.fulfilled, (state, action) => {
				const object = action?.payload;
				const index = state.objectListData.objects.findIndex(item => item?._id == object?._id)
				const objects = [ ...state.objectListData.objects ];
				objects.splice(index, 1, object)
				state.objectListData = { ...state.objectListData, objects }
			})
			
	},
})

export const {
	setSelectedObjectData
} = workspaceSlice.actions

export default workspaceSlice.reducer