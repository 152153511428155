import React, { useEffect, useMemo, useRef } from 'react';
import { onBlankLayout } from 'store/slices/themeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Result, Button } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

const AppRoute = ({ component: Component, routeKey, blankLayout, permissions, ...props }) => {

	const dispatch = useDispatch()
	const user = useSelector((state) => state?.auth.userInfo);
	const { current_project } = user;
	const navigate = useNavigate();
	const location = useLocation();
	const isMount = useRef(false);
	
	const isHasPermission = useMemo(() => {
		const { systemPermissions, projectPermissions, current_project } = user;
		if(!permissions) {
			return true
		}
		if(systemPermissions?.some(i => permissions?.includes(i?.name))) {
			return true
		}
		if(projectPermissions?.some(i => (i?.name ==='VIEW_PROJECT' && i?.project == current_project)) && projectPermissions?.some(i => (permissions.includes(i?.name) && i?.project==current_project))) {
			return true
		}
		return false

	}, [user, permissions])

	useEffect(() => {
		if(!user) return
		if(!isMount.current) {
			isMount.current = true
			return
		}
		const redirectUrl = location?.pathname.split('/').splice(0,4).join('/')+'/list';
		if(redirectUrl == location.pathname || location.pathname.includes('dashboards')) {
			return
		}
		navigate(location?.pathname.split('/').splice(0,4).join('/')+'/list')
	},[ current_project ])

	useEffect(() => {
		const isBlank = blankLayout ? true : false
		dispatch(onBlankLayout(isBlank))

	}, [blankLayout])

	if (!isHasPermission) {
		return <Result
			status="403"
			title="403"
			subTitle="Sorry, you are not authorized to access this page."
			extra={<Button onClick={()=> navigate('/')} type="primary">Back Home</Button>}
		/>
	}

	return (
		<Component {...props} />
	)
}

export default AppRoute