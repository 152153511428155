export const SYSTEM_PERMISSIONS = {
    ADMIN: "Admin",
    // ANDROID_APP: "Android App",
    MANAGE_PROJECTS: "Manage Projects",
    MANAGE_USERS: "Manage Users",
}

export const PROJECT_PERMISSIONS = {
    COLLECT_DATA: "Collect Data",
    MANAGE_DATA: "Manage Data",
    MANAGE_DATA_ENTRY: "Manage Data",
    MANAGE_DATA_STRUCTURE: "Manage Data Structure",
    VIEW_DATA: "View Data",

    VIEW_DASHBOARDS: "View Dashboards",
    MANAGE_DASHBOARDS: "Manage Dashboards",

    MANAGE_SUBMISSIONS: "Manage Submissions",
    VIEW_SUBMISSIONS: "View Submissions",

    VIEW_INDICATORS: "View Indicators",
    MANAGE_INDICATORS: "Manage Indicators",

    MANAGE_FORMS: "Manage Forms",
    VIEW_FORMS: "View Forms",

    // MANAGE_TRANSLATIONS: "Manage Translations",
}
export const DATA_ACCESS_PERMISSIONS = {
    VIEW_DATA: "View Data",
    // MANAGE_DATA: "Manage Data",
    MANAGE_DATA: "Manage Data",
    COLLECT_DATA: "Collect Data",
    MANAGE_DATA_STRUCTURE: "Manage Data Structure",
}
export const DASHBOARD_PERMISSIONS = {
    VIEW_DASHBOARDS: "View Dashboards",
    MANAGE_DASHBOARDS: "Manage Dashboards",
}
export const SUBMISSIONS_PERMISSIONS = {
    VIEW_SUBMISSIONS: "View Submissions",
    MANAGE_SUBMISSIONS: "Manage Submissions",
}

export const INDICATORS_PERMISSIONS = {
    VIEW_INDICATORS: "View Indicators",
    MANAGE_INDICATORS: "Manage Indicators",
}

export const FORMS_PERMISSIONS = {
    VIEW_FORMS: "View Forms",
    MANAGE_FORMS: "Manage Forms",
}



export const SYSTEM_PERMISSIONS_SERVER = {
    ADMIN: "ADMIN",
    // ANDROID_APP: "ANDROID_APP",
    MANAGE_PROJECTS: "MANAGE_PROJECTS",
    MANAGE_USERS: "MANAGE_USERS",
}

export const PROJECT_PERMISSIONS_SERVER = {

    VIEW_DATA: "VIEW_DATA",
    COLLECT_DATA: "COLLECT_DATA",
    MANAGE_DATA: "MANAGE_DATA",
    MANAGE_DATA_ENTRY: "MANAGE_DATA",
    MANAGE_DATA_STRUCTURE: "MANAGE_DATA_STRUCTURE",

    VIEW_DASHBOARDS: "VIEW_DASHBOARDS",
    MANAGE_DASHBOARDS: "MANAGE_DASHBOARDS",

    MANAGE_FORMS: "MANAGE_FORMS",
    VIEW_FORMS: "VIEW_FORMS",

    MANAGE_INDICATORS: "MANAGE_INDICATORS",
    VIEW_INDICATORS: "VIEW_INDICATORS",

    MANAGE_SUBMISSIONS: "MANAGE_SUBMISSIONS",
    VIEW_SUBMISSIONS: "VIEW_SUBMISSIONS",
   
    VIEW_PROJECT: "VIEW_PROJECT",

    // MANAGE_TRANSLATIONS: "MANAGE_TRANSLATIONS",
}