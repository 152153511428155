import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FormService from 'services/FormService';
import SubmissionService from 'services/SubmissionService';

export const initialState = {
	objectListData: {
		objects: [],
		isLoading: false,
		isLoaded: false,
	},
	selectedObjectData: {
		object: null,
		isLoading: false,
		isLoaded: false,
	},
    isToggleLoading: false
}



export const getObjects = createAsyncThunk('submission/getObjects', async (params, { rejectWithValue }) => {
	try {
		const response = await SubmissionService.getObjects(params);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const updateObject = createAsyncThunk('submission/updateObject', async ({id, data}, { rejectWithValue }) => {
	try {
		const response = await SubmissionService.updateObject(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const deleteObject = createAsyncThunk('submission/deleteObject', async (id, { rejectWithValue }) => {
	try {
		const response = await SubmissionService.deleteObject(id);
		return id
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const updateStatus = createAsyncThunk('submission/updateStatus', async ({id, data}, { rejectWithValue }) => {
	try {
		const response = await SubmissionService.updateStatus(id, data);
		return { object: response?.object, id}
		
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})


export const objectSlice = createSlice({
	name: 'submission',
	initialState,
	reducers: {
		setSelectedObjectData: (state, action) => {
			const { object } = action?.payload;
			state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getObjects.fulfilled, (state, action) => {
				const { objects, page, totalPages, totalRecords, limit } = action?.payload?.result
				state.objectListData = { ...state.objectListData, objects, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
			})
			.addCase(getObjects.pending, (state) => {
				state.objectListData = { ...state.objectListData, isLoaded: false, isLoading: true }
			})
			.addCase(getObjects.rejected, (state) => {
				state.objectListData = { ...state.objectListData, isLoaded: true, isLoading: false }
			})
			.addCase(deleteObject.fulfilled, (state, action) => {
				const objectId = action?.payload;
				const objects = state.objectListData.objects.filter(object => object?._id !== objectId);
				state.objectListData = { ...state.objectListData, objects }
			})
		
			.addCase(updateObject.fulfilled, (state, action) => {
				const object = action?.payload;
				const index = state.objectListData.objects.findIndex(item => item?._id == object?._id)
				const objects = [ ...state.objectListData.objects ];
				objects.splice(index, 1, object)
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(updateStatus.fulfilled, (state, action) => {
				const { object, id } = action?.payload;
				if(!object) {
					const objects = state.objectListData.objects.filter(object => object?._id !== id);
					state.objectListData = { ...state.objectListData, objects }
				}
				else {
					const index = state.objectListData.objects.findIndex(item => item?._id == object?._id)
					const objects = [ ...state.objectListData.objects ];
					objects.splice(index, 1, object)
					state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
					state.objectListData = { ...state.objectListData, objects }
				}
			})
			
	},
})

export const {
	setSelectedObjectData
} = objectSlice.actions

export default objectSlice.reducer