import React from 'react'
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import moment from 'moment';
import 'moment-timezone';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
moment.tz.setDefault("UTC");

root.render(
    // <React.StrictMode>
        <App />
    // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
