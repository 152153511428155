import fetch from 'auth/FetchInterceptor'

export default class UserService {

  static async updateProfile(data) {
    return await fetch({
      url: '/users/me',
      method: 'put',
      data: data
    })
  }
  static async uploadAvatar(data) {
    return await fetch({
      url: '/users/me/avatar',
      method: 'post',
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
  }
  static async deleteAvatar() {
    return await fetch({
      url: '/users/me/avatar',
      method: 'delete',
    })
  }
  static async changePassword(data) {
    return await fetch({
      url: '/users/me/change-password',
      method: 'post',
      data: data
    })
  }
  static async createUser(data) {
    return await fetch({
      url: '/users',
      method: 'post',
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
  }
  static async getUsers(params) {
    return await fetch({
      url: '/users',
      method: 'get',
      params: params
    })
  }
  static async getUser(id) {
    return await fetch({
      url: `/users/${id}`,
      method: 'get',
    })
  }
  static async updateUser(id, data) {
    return await fetch({
      url: `/users/${id}`,
      method: 'put',
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
  }
  static async deleteUser(id) {
    return await fetch({
      url: `/users/${id}`,
      method: 'delete',
    })
  }
  static async partialUpdateUser(id, data) {
    return await fetch({
      url: `/users/${id}`,
      method: 'patch',
      data: data
    })
  }
  static async getProjectUsers(params) {
    return await fetch({
      url: 'users/project-users',
      method: 'get',
      params: params
    })
  }
  
  static async import(data) {
    return await fetch({
      url: `/users/import`,
      method: 'post',
      data: data,
      headers: { "Content-Type": "multipart/form-data" },

    })
  }
  static async bulkEditPermissions(data) {
    return await fetch({
      url: `/users/bulk-edit-permissions`,
      method: 'post',
      data: data,

    })
  }
  
}