import fetch from 'auth/FetchInterceptor'

export default class ProjectService {

  static async createProject(data) {
    return await fetch({
      url: '/projects',
      method: 'post',
      data: data,
    })
  }
  static async getProjects(params) {
    return await fetch({
      url: '/projects',
      method: 'get',
      params: params
    })
  }
  static async getProject(id) {
    return await fetch({
      url: `/projects/${id}`,
      method: 'get',
    })
  }
  static async updateProject(id, data) {
    return await fetch({
      url: `/projects/${id}`,
      method: 'put',
      data: data,
    })
  }
  static async deleteProject(id) {
    return await fetch({
      url: `/projects/${id}`,
      method: 'delete',
    })
  }

  
}