import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from 'services/UserService';
import store from '../index';
import { setUserInfo } from './authSlice';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	errors: {},
	userListData: {
		users: [],
		isLoading: false,
		isLoaded: false,
	},
	selectedUserData: {
		user: null,
		isLoading: false,
		isLoaded: false,
	},
	projectUsers: []
}

export const updateProfile = createAsyncThunk('user/updateProfile', async (data, { rejectWithValue }) => {
	try {
		const response = await UserService.updateProfile(data);
		if (response.user) {
			const user = response.user;
			store.dispatch(setUserInfo(user))
			return user;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const uploadAvatar = createAsyncThunk('user/uploadAvatar', async (data, { rejectWithValue }) => {
	try {
		const response = await UserService.uploadAvatar(data);
		if (response.user) {
			const user = response.user;
			store.dispatch(setUserInfo(user))
			return user;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data.errors })
	}
})
export const deleteAvatar = createAsyncThunk('user/deleteAvatar', async (data, { rejectWithValue }) => {
	try {
		const response = await UserService.deleteAvatar();
		if (response.user) {
			const user = response.user;
			store.dispatch(setUserInfo(user))
			return user;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data })
	}
})
export const changePassword = createAsyncThunk('user/deleteAvatar', async (data, { rejectWithValue }) => {
	try {
		const response = await UserService.changePassword(data);
		if (response.user) {
			const user = response.user;
			return user;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const createUser = createAsyncThunk('user/createUser', async (data, { rejectWithValue }) => {
	try {
		const response = await UserService.createUser(data);
		if (response.user) {
			const user = response.user;
			return user;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const getUsers = createAsyncThunk('user/getUsers', async (params, { rejectWithValue }) => {
	try {
		const response = await UserService.getUsers(params);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const getUser = createAsyncThunk('user/getUser', async (id, { rejectWithValue }) => {
	try {
		const response = await UserService.getUser(id);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const updateUser = createAsyncThunk('user/updateUser', async ({id, data}, { rejectWithValue }) => {
	try {
		const response = await UserService.updateUser(id, data);
		if (response.user) {
			const user = response.user;
			return user;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const deleteUser = createAsyncThunk('user/deleteUser', async (id, { rejectWithValue }) => {
	try {
		const response = await UserService.deleteUser(id);
		return id
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const partialUpdateUser = createAsyncThunk('user/partialUpdateUser', async ({id, data}, { rejectWithValue }) => {
	try {
		const response = await UserService.partialUpdateUser(id, data);
		if (response.user) {
			const user = response.user;
			return user;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const getProjectUsers = createAsyncThunk('user/getProjectUsers', async (params, { rejectWithValue }) => {
	try {
		const response = await UserService.getProjectUsers(params);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const importData = createAsyncThunk('user/importData', async (formData, { rejectWithValue }) => {
	try {
		const response = await UserService.import(formData);
		return response
	} catch (err) {
		console.log(err?.response);
		return rejectWithValue({ message: err?.response.data || 'Error', errors: err?.response?.data })
	}
})

export const usersSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setSelectedUserData: (state, action) => {
			const { user } = action?.payload;
			state.selectedUserData = { ...state.selectedUserData, user, isLoaded: true, isLoading: false }
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(updateProfile.pending, (state) => {
				state.loading = true
			})
			.addCase(updateProfile.fulfilled, (state, action) => {
				state.loading = false;
				state.errors = {}
			})
			.addCase(updateProfile.rejected, (state, action) => {
				state.loading = false;
				state.errors = action?.payload?.errors;
			})
			.addCase(getUsers.fulfilled, (state, action) => {
				const { users, page, totalPages, totalRecords, limit } = action?.payload?.result
				state.userListData = { ...state.userListData, users, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
			})
			.addCase(getUsers.pending, (state) => {
				state.userListData = { ...state.userListData, isLoaded: false, isLoading: true }
			})
			.addCase(getUsers.rejected, (state) => {
				state.userListData = { ...state.userListData, isLoaded: true, isLoading: false }
			})
			.addCase(getUser.fulfilled, (state, action) => {
				const { user } = action?.payload;
				state.selectedUserData = { ...state.selectedUserData, user, isLoaded: true, isLoading: false }
			})
			.addCase(createUser.fulfilled, (state, action) => {
				const user  = action?.payload;
				state.selectedUserData = { ...state.selectedUserData, user, isLoaded: true, isLoading: false }
			})
			.addCase(getUser.pending, (state) => {
				state.selectedUserData = { ...state.selectedUserData, isLoaded: false, isLoading: true }
			})
			.addCase(getUser.rejected, (state) => {
				state.selectedUserData = { ...state.selectedUserData, isLoaded: true, isLoading: false }
			})
			.addCase(deleteUser.fulfilled, (state, action) => {
				const userId = action?.payload;
				const users = state.userListData.users.filter(user => user?._id !== userId);
				state.userListData = { ...state.userListData, users }
			})
			.addCase(partialUpdateUser.fulfilled, (state, action) => {
				const user  = action?.payload;
				state.selectedUserData = { ...state.selectedUserData, user, isLoaded: true, isLoading: false }
			})
			.addCase(getProjectUsers.fulfilled, (state, action) => {
				const { objects } = action?.payload?.result
				state.projectUsers = [ ...objects ]
			})
			.addCase(importData.fulfilled, (state, action) => {
				const { users, page, totalPages, totalRecords, limit } = action?.payload?.result
				state.userListData = { ...state.userListData, users, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
			})
	},
})

export const {

	setSelectedUserData

} = usersSlice.actions

export default usersSlice.reducer