import fetch from 'auth/FetchInterceptor'
import { stringify } from 'qs';

const urlKey = '/data-table';

export default class DataTableService {

  static async createObjects(id, data) {
    return await fetch({
      url: `/${id}${urlKey}/import`,
      method: 'post',
      data: data,
    })
  }
  static async getObjects(id, params) {
    return await fetch({
      url: `/${id}${urlKey}`,
      method: 'get',
      params: params,
      paramsSerializer: params => {
        return stringify(params)
      }
    })
  }
  static async deleteObjects(id, ids) {
    return await fetch({
      url: `/${id}${urlKey}`,
      method: 'delete',
      data: { ids }
    })
  }

  static async updateObject(tableSchemaId, id, data) {
    return await fetch({
      url: `/${tableSchemaId}${urlKey}/${id}`,
      method: 'put',
      data: data
    })
  }
  static async createObject(id, data) {
    return await fetch({
      url: `/${id}${urlKey}/submit`,
      method: 'post',
      data: data,
    })
  }
  static async assignUsers(tableSchemaId, id, data) {
    return await fetch({
      url: `/${tableSchemaId}${urlKey}/assign-users/${id}`,
      method: 'patch',
      data: data,
    })
  }
  static async deleteObjectsByFilter(id, params) {
    return await fetch({
      url: `/${id}${urlKey}/bulk-delete?${params}`,
      method: 'delete',
    })
  }
  static async bulkAssignUsers(id, data, params) {
    return await fetch({
      url: `/${id}${urlKey}/bulk-assign-users?${params}`,
      method: 'patch',
      data
    })
  }
  static async bulkUpdate(id, data, params) {
    return await fetch({
      url: `/${id}${urlKey}/bulk-update?${params}`,
      method: 'patch',
      data
    })
  }
  static async getRecord(tableSchemaId, id) {
    return await fetch({
      url: `/${tableSchemaId}${urlKey}/public/data/${id}`,
      method: 'get',
      paramsSerializer: params => {
        return stringify(params)
      }
    })
  }
  static async copyRecord(tableSchemaId, id, data) {
    return await fetch({
      url: `/${tableSchemaId}${urlKey}/copy/${id}`,
      method: 'post',
      data
    })
  }
  static async bulkAction(id, data, params) {
    return await fetch({
      url: `/${id}${urlKey}/bulk-action?${params}`,
      method: 'patch',
      data
    })
  }
}