import fetch from 'auth/FetchInterceptor'

const urlKey = '/dashboards';

export default class DashboardService {

  static async createObject(data) {
    return await fetch({
      url: urlKey,
      method: 'post',
      data: data,
    })
  }
  static async getObjects(params) {
    return await fetch({
      url: urlKey,
      method: 'get',
      params: params
    })
  }

  static async updateObject(id, data) {
    return await fetch({
      url: urlKey + `/${id}`,
      method: 'put',
      data: data,
    })
  }
  static async deleteObject(id) {
    return await fetch({
      url: urlKey + `/${id}`,
      method: 'delete',
    })
  }

  
}