import fetch from 'auth/FetchInterceptor'
import { METABASE_API_BASE_URL } from 'configs/AppConfig';

const urlKey = '/metabase/dashboards';

export default class MetabaseService {

  static async getData(id, params) {
    return await fetch({
      url: urlKey + `/${id}`,
      method: 'get',
      params: params
    })
  }
  static async getDashboards(params) {
    return await fetch({
      url: `${urlKey}`,
      method: 'get',
      params: params,
    })
  }

  
}