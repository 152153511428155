import fetch from 'auth/FetchInterceptor'
import { stringify } from 'qs';

const urlKey = '/submissions';

export default class SubmissionService {

  static async createObject(data) {
    return await fetch({
      url: urlKey,
      method: 'post',
      data: data,
    })
  }
  static async getObjects(params) {
    return await fetch({
      url: urlKey,
      method: 'get',
      params: params,
      paramsSerializer: params => {
        return stringify(params)
      }
    })
  }
  static async getObject(id) {
    return await fetch({
      url: urlKey + `/${id}`,
      method: 'get',
    })
  }
  static async updateObject(id, data) {
    return await fetch({
      url: urlKey + `/${id}`,
      method: 'put',
      data: data,
    })
  }
  static async deleteObject(id) {
    return await fetch({
      url: urlKey + `/${id}`,
      method: 'delete',
    })
  }
  static async updateStatus(id, data) {
    return await fetch({
      url: urlKey + `/${id}/status`,
      method: 'put',
      data: data,
    })
  }
  
  static async submitObject(id, data) {
    return await fetch({
      url: `${urlKey}/${id}/submit`,
      method: 'post',
      data: data,
    })
  }
  
}