import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ProjectService from 'services/ProjectService';

export const initialState = {
	projectListData: {
		projects: [],
		isLoading: false,
		isLoaded: false,
	},
	selectedProjectData: {
		project: null,
		isLoading: false,
		isLoaded: false,
	}
}


export const createProject = createAsyncThunk('project/createProject', async (data, { rejectWithValue }) => {
	try {
		const response = await ProjectService.createProject(data);
		if (response.project) {
			const project = response.project;
			return project;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const getProjects = createAsyncThunk('project/getProjects', async (params, { rejectWithValue }) => {
	try {
		const response = await ProjectService.getProjects(params);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const getProject = createAsyncThunk('project/getProject', async (id, { rejectWithValue }) => {
	try {
		const response = await ProjectService.getProject(id);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const updateProject = createAsyncThunk('project/updateProject', async ({id, data}, { rejectWithValue }) => {
	try {
		const response = await ProjectService.updateProject(id, data);
		if (response.project) {
			const project = response.project;
			return project;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const deleteProject = createAsyncThunk('project/deleteProject', async (id, { rejectWithValue }) => {
	try {
		const response = await ProjectService.deleteProject(id);
		return id
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const projectSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		setSelectedProjectData: (state, action) => {
			const { project } = action?.payload;
			state.selectedProjectData = { ...state.selectedProjectData, project, isLoaded: true, isLoading: false }
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getProjects.fulfilled, (state, action) => {
				const { objects: projects, page, totalPages, totalRecords, limit } = action?.payload?.result
				state.projectListData = { ...state.projectListData, projects, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
			})
			.addCase(getProjects.pending, (state) => {
				state.projectListData = { ...state.projectListData, isLoaded: false, isLoading: true }
			})
			.addCase(getProjects.rejected, (state) => {
				state.projectListData = { ...state.projectListData, isLoaded: true, isLoading: false }
			})
			.addCase(getProject.fulfilled, (state, action) => {
				const { project } = action?.payload;
				state.selectedProjectData = { ...state.selectedProjectData, project, isLoaded: true, isLoading: false }
			})
			.addCase(getProject.pending, (state) => {
				state.selectedProjectData = { ...state.selectedProjectData, isLoaded: false, isLoading: true }
			})
			.addCase(getProject.rejected, (state) => {
				state.selectedProjectData = { ...state.selectedProjectData, isLoaded: true, isLoading: false }
			})
			.addCase(deleteProject.fulfilled, (state, action) => {
				const projectId = action?.payload;
				const projects = state.projectListData.projects.filter(project => project?._id !== projectId);
				state.projectListData = { ...state.projectListData, projects }
			})
			.addCase(updateProject.fulfilled, (state, action) => {
				const project = action?.payload;
				const index = state.projectListData.projects.findIndex(item => item?._id == project?._id)
				const projects = [ ...state.projectListData.projects ];
				projects.splice(index, 1, project)
				state.projectListData = { ...state.projectListData, projects }
			})
			
	},
})

export const {
	setSelectedProjectData
} = projectSlice.actions

export default projectSlice.reducer