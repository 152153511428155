import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import IndicatorService from 'services/IndicatorService';

export const initialState = {
	objectListData: {
		objects: [],
		isLoading: false,
		isLoaded: false,
	},
	targetListData: {
		objects: [],
		isLoading: false,
		isLoaded: false,
	},
	selectedObjectData: {
		object: null,
		isLoading: false,
		isLoaded: false,
	},
	indicatorValues: {
		values: [],
		isLoading: false,
		isLoaded: false,
		indicatorValue: null
	}
}


export const createObject = createAsyncThunk('indicator/createObject', async (data, { rejectWithValue }) => {
	try {
		const response = await IndicatorService.createObject(data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const getObjects = createAsyncThunk('indicator/getObjects', async (params, { rejectWithValue }) => {
	try {
		const response = await IndicatorService.getObjects(params);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const getObject = createAsyncThunk('indicator/getObject', async (id, { rejectWithValue }) => {
	try {
		const response = await IndicatorService.getObject(id);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const updateObject = createAsyncThunk('indicator/updateObject', async ({id, data}, { rejectWithValue }) => {
	try {
		const response = await IndicatorService.updateObject(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const deleteObject = createAsyncThunk('indicator/deleteObject', async (id, { rejectWithValue }) => {
	try {
		const response = await IndicatorService.deleteObject(id);
		return id
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const getStatistics = createAsyncThunk('indicator/getStatistics', async ({id, params}, { rejectWithValue }) => {
	try {
		const response = await IndicatorService.getStatistics(id, params);
		return { response, userLastSelections: params}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const updateYearlyField = createAsyncThunk('indicator/updateYearlyField', async ({id, data}, { rejectWithValue }) => {
	try {
		const response = await IndicatorService.updateYearlyField(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const getObjectsWithStatistics = createAsyncThunk('indicator/getObjectsWithStatistics', async (params, { rejectWithValue }) => {
	try {
		const response = await IndicatorService.getObjectsWithStatistics(params);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const duplicateObject = createAsyncThunk('indicator/duplicateObject', async ({id}, { rejectWithValue }) => {
	try {
		const response = await IndicatorService.duplicateObject(id);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const objectSlice = createSlice({
	name: 'indicator',
	initialState,
	reducers: {
		setSelectedObjectData: (state, action) => {
			const { object } = action?.payload;
			state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getObjects.fulfilled, (state, action) => {
				const { objects, page, totalPages, totalRecords, limit } = action?.payload?.result
				state.objectListData = { ...state.objectListData, objects, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
			})
			.addCase(getObjects.pending, (state) => {
				state.objectListData = { ...state.objectListData, isLoaded: false, isLoading: true }
			})
			.addCase(getObjects.rejected, (state) => {
				state.objectListData = { ...state.objectListData, isLoaded: true, isLoading: false }
			})
			.addCase(getObject.fulfilled, (state, action) => {
				const { object } = action?.payload;
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
			})
			.addCase(getObject.pending, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isLoaded: false, isLoading: true }
			})
			.addCase(getObject.rejected, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isLoaded: true, isLoading: false }
			})
			.addCase(deleteObject.fulfilled, (state, action) => {
				const objectId = action?.payload;
				const objects = state.objectListData.objects.filter(object => object?._id !== objectId);
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(updateObject.fulfilled, (state, action) => {
				const object = action?.payload;
				const objects = [ ...state.objectListData.objects.filter(item => item?._id !== object?._id), object];
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(updateYearlyField.fulfilled, (state, action) => {
				const object = action?.payload;
				const objects = [ ...state.objectListData.objects.filter(item => item?._id !== object?._id), object];
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(getStatistics.fulfilled, (state, action) => {
				const { response: {indicatorValues, indicatorValue, userLastSelections}  } = action?.payload;
				if(state.selectedObjectData?.object && userLastSelections) {
					// console.log(userLastSelections);
					state.selectedObjectData = { ...state.selectedObjectData, object: { ...state.selectedObjectData.object, userLastSelections}, isLoaded: true, isLoading: false }
				}
				state.indicatorValues = { ...state.indicatorValues, values: indicatorValues, indicatorValue, isLoaded: true, isLoading: false }
			})
			.addCase(getStatistics.pending, (state) => {
				state.indicatorValues = { ...state.indicator, isLoaded: false, isLoading: true }
			})
			.addCase(getStatistics.rejected, (state) => {
				state.indicatorValues = { ...state.indicator, isLoaded: true, isLoading: false }
			})
			.addCase(getObjectsWithStatistics.fulfilled, (state, action) => {
				const { objects, page, totalPages, totalRecords, limit } = action?.payload?.result
				state.targetListData = { ...state.targetListData, objects, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
			})
			.addCase(getObjectsWithStatistics.pending, (state) => {
				state.targetListData = { ...state.targetListData, isLoaded: false, isLoading: true }
			})
			.addCase(getObjectsWithStatistics.rejected, (state) => {
				state.targetListData = { ...state.targetListData, isLoaded: true, isLoading: false }
			})
			.addCase(duplicateObject.fulfilled, (state, action) => {
				const object = action?.payload;
				const objects = [ ...state.objectListData.objects, object];
				state.objectListData = { ...state.objectListData, objects }
			})
			
	},
})

export const {
	setSelectedObjectData
} = objectSlice.actions

export default objectSlice.reducer