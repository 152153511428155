import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH, PUBLIC_PREFIX_PATH } from 'configs/AppConfig'
import { PROJECT_PERMISSIONS_SERVER, SYSTEM_PERMISSIONS_SERVER } from 'constants/PermissionsConstant'

export const authRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'set-password',
        path: `${AUTH_PREFIX_PATH}/set-password`,
        component: React.lazy(() => import('views/auth-views/authentication/set-password')),
        meta: {
            blankLayout: true
        }
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'error-page-1',
        path: `${AUTH_PREFIX_PATH}/error-page-1`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
    },
    {
        key: 'error-page-2',
        path: `${AUTH_PREFIX_PATH}/error-page-2`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-2')),
    },

   
]

export const protectedRoutes = [

    // *** dashboard section *** 

    //DASHBOARDS
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.analytic',
        path: `${APP_PREFIX_PATH}/dashboards/analytic`,
        component: React.lazy(() => import('views/app-views/dashboards/analytic')),
        meta: {
            permissions: ['ADMIN', 'MANAGE_DASHBOARDS', 'VIEW_DASHBOARDS']
        }
    },
    {
        key: 'dashboard.sales',
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        component: React.lazy(() => import('views/app-views/dashboards/sales')),
    },
    // {
    //     key: 'dashboard.analytics',
    //     path: `${APP_PREFIX_PATH}/dashboards/analytics`,
    //     component: React.lazy(() => import('views/app-views/analytics')),
    // },
    
    //DATA
    {
        key: 'dashboard.data',
        path: `${APP_PREFIX_PATH}/dashboard/data/list/*`,
        component: React.lazy(() => import('views/app-views/data')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.MANAGE_DATA_STRUCTURE, PROJECT_PERMISSIONS_SERVER.VIEW_DATA]
        }
    },
    {
        key: 'dashboard.data.add',
        path: `${APP_PREFIX_PATH}/dashboard/data/add`,
        component: React.lazy(() => import('views/app-views/data/DataAdd')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.MANAGE_DATA_STRUCTURE]
        }
    },
    {
        key: 'dashboard.data.edit',
        path: `${APP_PREFIX_PATH}/dashboard/data/edit/:id`,
        component: React.lazy(() => import('views/app-views/data/DataEdit')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.MANAGE_DATA_STRUCTURE]
        }
    },
    {
        key: 'dashboard.data.view',
        path: `${APP_PREFIX_PATH}/dashboard/data/view/:id`,
        component: React.lazy(() => import('views/app-views/data/DataView')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.MANAGE_DATA_STRUCTURE, PROJECT_PERMISSIONS_SERVER.MANAGE_DATA_ENTRY, PROJECT_PERMISSIONS_SERVER.VIEW_DATA]
        }
    },
    {
        key: 'dashboard.data.view.data-structure',
        path: `${APP_PREFIX_PATH}/dashboard/data/view/:id/data-structure`,
        component: React.lazy(() => import('views/app-views/data/DataStructureView')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.MANAGE_DATA_STRUCTURE, PROJECT_PERMISSIONS_SERVER.VIEW_DATA]
        }
    },
    
    {
        key: 'dashboard.data.add-record',
        path: `${APP_PREFIX_PATH}/dashboard/data/:id/add`,
        component: React.lazy(() => import('views/app-views/data/DataRecordAdd')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.MANAGE_DATA_ENTRY,PROJECT_PERMISSIONS_SERVER.COLLECT_DATA]
        }
    },

    //INDICATORS
    {
        key: 'dashboard.indicators',
        path: `${APP_PREFIX_PATH}/dashboard/indicators/list/*`,
        component: React.lazy(() => import('views/app-views/indicators')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.VIEW_INDICATORS, PROJECT_PERMISSIONS_SERVER.MANAGE_INDICATORS]
        }
    },
    {
        key: 'dashboard.indicators.target',
        path: `${APP_PREFIX_PATH}/dashboard/indicators/target-view`,
        component: React.lazy(() => import('views/app-views/indicators/IndicatorTargetViewList')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.VIEW_INDICATORS, PROJECT_PERMISSIONS_SERVER.MANAGE_INDICATORS]
        }
    },
    {
        key: 'dashboard.indicators.add',
        path: `${APP_PREFIX_PATH}/dashboard/indicators/add`,
        component: React.lazy(() => import('views/app-views/indicators/IndicatorAdd')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.MANAGE_INDICATORS]
        }
    },
    {
        key: 'dashboard.indicators.edit',
        path: `${APP_PREFIX_PATH}/dashboard/indicators/edit/:id`,
        component: React.lazy(() => import('views/app-views/indicators/IndicatorEdit')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.MANAGE_INDICATORS]
        }
    },
    {
        key: 'dashboard.indicators.view',
        path: `${APP_PREFIX_PATH}/dashboard/indicators/view/:id`,
        component: React.lazy(() => import('views/app-views/indicators/IndicatorView')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.VIEW_INDICATORS, PROJECT_PERMISSIONS_SERVER.MANAGE_INDICATORS]
        }
    },
    //FORMS
    {
        key: 'dashboard.forms',
        path: `${APP_PREFIX_PATH}/dashboard/forms`,
        component: React.lazy(() => import('views/app-views/forms')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.VIEW_FORMS, PROJECT_PERMISSIONS_SERVER.MANAGE_FORMS]
        }
    },
    {
        key: 'dashboard.form.list',
        path: `${APP_PREFIX_PATH}/dashboard/forms/list`,
        component: React.lazy(() => import('views/app-views/forms/FormList')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.VIEW_FORMS, PROJECT_PERMISSIONS_SERVER.MANAGE_FORMS]
        }
    },
    {
        key: 'dashboard.form.add',
        path: `${APP_PREFIX_PATH}/dashboard/forms/add`,
        component: React.lazy(() => import('views/app-views/forms/FormAdd')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.MANAGE_FORMS]
        }
    },
    {
        key: 'dashboard.form.edit',
        path: `${APP_PREFIX_PATH}/dashboard/forms/edit/:id`,
        component: React.lazy(() => import('views/app-views/forms/FormEdit')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.MANAGE_FORMS]
        }
    },
    {
        key: 'dashboard.form.structure',
        path: `${APP_PREFIX_PATH}/dashboard/forms/:id/structure`,
        component: React.lazy(() => import('views/app-views/forms/FormStructureView')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.VIEW_FORMS, PROJECT_PERMISSIONS_SERVER.MANAGE_FORMS]
        }
    },
    {
        key: 'dashboard.data-entry.form.builder',
        path: `${APP_PREFIX_PATH}/dashboard/forms/builder/:id`,
        component: React.lazy(() => import('views/app-views/forms/FormBuilder')),
    },

    //FORMS
    {
        key: 'dashboard.submissions',
        path: `${APP_PREFIX_PATH}/dashboard/submissions`,
        component: React.lazy(() => import('views/app-views/submissions')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.VIEW_SUBMISSIONS, PROJECT_PERMISSIONS_SERVER.MANAGE_SUBMISSIONS]
        }
    },
    {
        key: 'dashboard.form.list',
        path: `${APP_PREFIX_PATH}/dashboard/submissions/list`,
        component: React.lazy(() => import('views/app-views/submissions/SubmissionList')),
        meta: {
            permissions: [SYSTEM_PERMISSIONS_SERVER.ADMIN, PROJECT_PERMISSIONS_SERVER.VIEW_SUBMISSIONS, PROJECT_PERMISSIONS_SERVER.MANAGE_SUBMISSIONS]
        }
    },

    // *** end of dashboard section *** 

    // *** administration section *** 

    //USERS
    {
        key: 'administration.user',
        path: `${APP_PREFIX_PATH}/administration/user`,
        component: React.lazy(() => import('views/app-views/users')),
    },
    {
        key: 'administration.user.list',
        path: `${APP_PREFIX_PATH}/administration/user/user-list`,
        component: React.lazy(() => import('views/app-views/users/UserList')),
        meta: {
            permissions: ['ADMIN', 'MANAGE_USERS']
        }
    },
    {
        key: 'administration.user.create',
        path: `${APP_PREFIX_PATH}/administration/user/create`,
        component: React.lazy(() => import('views/app-views/users/UserCreate')),
        meta: {
            permissions: ['ADMIN', 'MANAGE_USERS']
        }
    },
    {
        key: 'administration.user.edit',
        path: `${APP_PREFIX_PATH}/administration/user/edit/:id`,
        component: React.lazy(() => import('views/app-views/users/UserEdit')),
        meta: {
            permissions: ['ADMIN', 'MANAGE_USERS']
        }
    },

    //PROJECTS
    {
        key: 'administration.project',
        path: `${APP_PREFIX_PATH}/administration/project`,
        component: React.lazy(() => import('views/app-views/projects')),
    },
    {
        key: 'administration.project.list',
        path: `${APP_PREFIX_PATH}/administration/project/project-list`,
        component: React.lazy(() => import('views/app-views/projects/ProjectList')),
        meta: {
            permissions: ['ADMIN', 'MANAGE_PROJECTS']
        }
    },
    {
        key: 'administration.project.create',
        path: `${APP_PREFIX_PATH}/administration/project/create`,
        component: React.lazy(() => import('views/app-views/projects/ProjectCreate')),
        meta: {
            permissions: ['ADMIN', 'MANAGE_PROJECTS']
        }
    },
    {
        key: 'administration.project.edit',
        path: `${APP_PREFIX_PATH}/administration/project/edit/:id`,
        component: React.lazy(() => import('views/app-views/projects/ProjectEdit')),
        meta: {
            permissions: ['ADMIN', 'MANAGE_PROJECTS']
        }
    },
     //Workspaces
     {
        key: 'administration.workspace',
        path: `${APP_PREFIX_PATH}/administration/workspace`,
        component: React.lazy(() => import('views/app-views/workspaces')),
    },
    {
        key: 'administration.workspace.list',
        path: `${APP_PREFIX_PATH}/administration/workspace/workspace-list`,
        component: React.lazy(() => import('views/app-views/workspaces/WorkspaceList')),
        meta: {
            permissions: ['ADMIN']
        }
    },
    {
        key: 'administration.workspace.create',
        path: `${APP_PREFIX_PATH}/administration/workspace/create`,
        component: React.lazy(() => import('views/app-views/workspaces/WorkspaceCreate')),
        meta: {
            permissions: ['ADMIN']
        }
    },
    {
        key: 'administration.workspace.edit',
        path: `${APP_PREFIX_PATH}/administration/workspace/edit/:id`,
        component: React.lazy(() => import('views/app-views/workspaces/WorkspaceEdit')),
        meta: {
            permissions: ['ADMIN']
        }
    },

    // *** end of administration section *** 
    {
        key: 'pages.setting',
        path: `${APP_PREFIX_PATH}/pages/setting/*`,
        component: React.lazy(() => import('views/app-views/pages/setting')),
    },

]

export const publicRoutes = [
     //PUBLIC FORMS
     {
        key: 'form',
        path: `${PUBLIC_PREFIX_PATH}/form/:id`,
        component: React.lazy(() => import('views/public-views/forms/FormNewSubmission')),
        meta: {
            blankLayout: true
        }
    },
    {
        key: 'recordView',
        path: `${PUBLIC_PREFIX_PATH}/data/:tableSchemaId/view/:id`,
        component: React.lazy(() => import('views/public-views/data/DataRecordView')),
        meta: {
            blankLayout: true
        }
    },
    {
        key: 'error-page-1',
        path: `${PUBLIC_PREFIX_PATH}/error`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
    },
]