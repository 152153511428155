import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import DashboardService from 'services/DashboardService';

export const initialState = {
	objectListData: {
		objects: [],
		isLoading: false,
		isLoaded: false,
	},
	selectedObjectData: {
		object: null,
		isLoading: false,
		isLoaded: false,
	},
}


export const createObject = createAsyncThunk('dashboard/createObject', async (data, { rejectWithValue }) => {
	try {
		const response = await DashboardService.createObject(data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const getObjects = createAsyncThunk('dashboard/getObjects', async (params, { rejectWithValue }) => {
	try {
		const response = await DashboardService.getObjects(params);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

// export const updateObject = createAsyncThunk('indicator/updateObject', async ({id, data}, { rejectWithValue }) => {
// 	try {
// 		const response = await DashboardService.updateObject(id, data);
// 		if (response.object) {
// 			const object = response.object;
// 			return object;
// 		} else {
// 			return rejectWithValue(response.message);
// 		}
// 	} catch (err) {
// 		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
// 	}
// })

export const deleteObject = createAsyncThunk('dashboard/deleteObject', async (id, { rejectWithValue }) => {
	try {
		const response = await DashboardService.deleteObject(id);
		return id
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})


export const objectSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		setSelectedObjectData: (state, action) => {
			const { object } = action?.payload;
			state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getObjects.fulfilled, (state, action) => {
				const { objects, page, totalPages, totalRecords, limit } = action?.payload?.result
				state.objectListData = { ...state.objectListData, objects, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
			})
			.addCase(getObjects.pending, (state) => {
				state.objectListData = { ...state.objectListData, isLoaded: false, isLoading: true }
			})
			.addCase(getObjects.rejected, (state) => {
				state.objectListData = { ...state.objectListData, isLoaded: true, isLoading: false }
			})
            .addCase(createObject.fulfilled, (state, action) => {
				const object = action?.payload;
                const objects = [...state.objectListData.objects, object];
				state.objectListData = { ...state.objectListData, objects,  isLoaded: true, isLoading: false }
			})
			.addCase(deleteObject.fulfilled, (state, action) => {
				const objectId = action?.payload;
				const objects = state.objectListData.objects.filter(object => object?._id !== objectId);
				state.objectListData = { ...state.objectListData, objects }
			})
			// .addCase(updateObject.fulfilled, (state, action) => {
			// 	const object = action?.payload;
			// 	const objects = [ ...state.objectListData.objects.filter(item => item?._id !== object?._id), object];
			// 	state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
			// 	state.objectListData = { ...state.objectListData, objects }
			// })
			
	},
})

export const {
	setSelectedObjectData
} = objectSlice.actions

export default objectSlice.reducer