import React, { lazy, Suspense, memo, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loading from 'components/shared-components/Loading';
import Views from 'views';
import { checkToken } from 'store/slices/authSlice';

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));

const Layouts = () => {

	const { token, checkTokenLoading } = useSelector(state => state.auth);
	const blankLayout = useSelector(state => state.theme.blankLayout);
	const direction = useSelector(state => state.theme.direction);
	const navType = useSelector(state => state.theme.navType);
	const navCollapsed = useSelector(state => state.theme.navCollapsed);
	const dispatch = useDispatch();
	const Layout = token && !blankLayout ? AppLayout : AuthLayout;
	useLayoutEffect(() => {
		if(token) {
			dispatch(checkToken())
		}
    },[])
	
	return (
		<Suspense fallback={<Loading cover="content"/>}>
			{!checkTokenLoading && <Layout direction={direction} navType={navType} navCollapsed={navCollapsed}>
				<Views />
			</Layout>}
		</Suspense>
	)
}

export default memo(Layouts)