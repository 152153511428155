import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import AuthService from 'services/AuthService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	errors: {},
	userInfo: {},
	currentWorkspace: localStorage.getItem('workspaceId'),
	token: localStorage.getItem(AUTH_TOKEN) || null,
	checkTokenLoading: false
}

export const signIn = createAsyncThunk('auth/signIn', async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {

		const response = await AuthService.login({ email, password })
		if (response.token) {
			const token = response.token;
			localStorage.setItem(AUTH_TOKEN, response.token);
			return {token, user: response.user};
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue(err.response?.data || 'Error')
	}
})


export const signUp = createAsyncThunk('auth/signUp', async (data, { rejectWithValue }) => {
	const { email, password, confirm_password, first_name, last_name, title, username } = data
	try {
		const response = await AuthService.register({ email, password, confirm_password, first_name, last_name, title, username });
		if (response.token) {
			const token = response.token;
			localStorage.setItem(AUTH_TOKEN, response.token);
			return {token, user: response.user};
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const signOut = createAsyncThunk('auth/signOut', async () => {
	localStorage.removeItem(AUTH_TOKEN);
	return true
})

export const checkToken = createAsyncThunk('auth/checkToken', async (data, { rejectWithValue }) => {
	try {
		const response = await AuthService.checkToken({});
		if (response.user) {
			const user = response.user;
			return user;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})
export const setPassword = createAsyncThunk('auth/setPassword', async ({data, token}, { rejectWithValue }) => {
	const { confirm_password, password } = data
	try {

		const {data: response} = await AuthService.setPassword({ confirm_password, password }, token)
		if (response.token) {
			const token = response.token;
			localStorage.setItem(AUTH_TOKEN, response.token);
			return { token, user: response.user } ;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue(err.response?.data || 'Error')
	}
})

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		},
		setUserInfo: (state, action) => {
			state.userInfo = {...action.payload}
		},
		setCurrentWorkspace: (state, action) => {
			state.currentWorkspace = action?.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.token
				state.userInfo = { ...action?.payload?.user }
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(checkToken.pending, (state) => {
				state.checkTokenLoading = true
			})
			.addCase(checkToken.fulfilled, (state, action) => {
				state.userInfo = { ...action?.payload }
				state.checkTokenLoading = false
			})
			.addCase(checkToken.rejected, (state, action) => {
				state.userInfo = {}
				state.checkTokenLoading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.userInfo = {};
				state.loading = false;
				state.token = null;
				state.redirect = '/';
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.token
				state.userInfo = { ...action?.payload?.user }
				state.errors = {}
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload?.message;
				state.errors = action?.payload?.errors
				state.showMessage = true
				state.loading = false
			})
			.addCase(setPassword.pending, (state) => {
				state.loading = true
			})
			.addCase(setPassword.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.token
				state.userInfo = { ...action?.payload?.user }
			})
			.addCase(setPassword.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess,
	setUserInfo,
	setCurrentWorkspace
} = authSlice.actions

export default authSlice.reducer