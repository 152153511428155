import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import user from './slices/userSlice'
import project from './slices/projectSlice'
import tableSchema from './slices/tableSchemaSlice'
import dataTable from './slices/dataTableSlice'
import indicator from './slices/indicatorSlice'
import form from './slices/formSlice'
import submission from './slices/submissionSlice'
import metabase from './slices/metabaseSlice'
import dashboard from './slices/dashboardSlice'
import workspace from './slices/workspaceSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        user,
        project,
        tableSchema,
        dataTable,
        indicator,
        form,
        submission,
        metabase,
        dashboard,
        workspace,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
