import fetch from 'auth/FetchInterceptor'

const urlKey = '/workspaces';

export default class WorkspaceService {

  static async createObject(data) {
    return await fetch({
      url: urlKey,
      method: 'post',
      data: data,
    })
  }
  static async getObjects(params) {
    return await fetch({
        url: urlKey,
      method: 'get',
      params: params
    })
  }
  static async getObject(id) {
    return await fetch({
      url: `${ urlKey}/${id}`,
      method: 'get',
    })
  }
  static async updateObject(id, data) {
    return await fetch({
      url: `${ urlKey}/${id}`,
      method: 'put',
      data: data,
    })
  }
  static async deleteObject(id) {
    return await fetch({
      url: `${ urlKey}/${id}`,
      method: 'delete',
    })
  }
  static async assignUsers(id, data) {
    return await fetch({
      url: urlKey + `/assign-users/${id}`,
      method: 'patch',
      data: data,
    })
  }
  static async assignProjects(id, data) {
    return await fetch({
      url: urlKey + `/assign-projects/${id}`,
      method: 'patch',
      data: data,
    })
  }

  
}