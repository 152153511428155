import fetch from 'auth/FetchInterceptor'
import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'

const AuthService = {}

AuthService.login = function (data) {
	return fetch({
		url: '/auth/login',
		method: 'post',
		data: data
	})
}

AuthService.register = function (data) {
	return fetch({
		url: '/auth/register',
		method: 'post',
		data: data
	})
}
AuthService.checkToken = function (data) {
	return fetch({
		url: '/auth/check',
		method: 'post',
		data: data
	})
}
AuthService.setPassword = function (data, token) {
	const service = axios.create({
		baseURL: API_BASE_URL,
		timeout: 60000*10
	})
	return service({
		url: `/auth/set-password?token=${token}`,
		method: 'post',
		data: data
	})
}
AuthService.forgotPassword = function (data) {
	return fetch({
		url: '/auth/forgot-password',
		method: 'post',
		data: data
	})
}

export default AuthService;